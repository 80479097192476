import {
  Container, ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary, Grid,
  Link,
  makeStyles, Paper,
  Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'
import Layout from '../components/layout'
import NewHeader from '../components/NewHeader'
import SEO from '../components/seo'
import Links from '../images/links.jpg'
const useStyles = makeStyles(theme => ({
  marginTop84: {
    marginTop: -120,
    marginBottom: 84,
  },
  none: {
    textDecoration: 'none',
  },
  menu: {
    float: 'right',
  },
  li: {
    display: 'inline-block',
  },
  link: {
    display: 'inline-block',
    fontSize: 15,
    margin: 8,
  },
}))
const data = [
  {
    name: 'Valediction function of 65th Batch',
    link: 'http://www.purplestream.com/nadt/',
  },
  {
    name: 'Finance Minister inaugurates DTRTI Bangalore campus',
    link: 'http://dtrti.purplestream.in/',
  },
  {
    name: 'OLTAS DASHBOARD',
    link: 'https://onlineservices.tin.nsdl.com',
  },
  {
    name: 'India Image',
    link: 'http://indiaimage.nic.in/',
  },
  {
    name: 'Ministry of Personnel,Public Grievances and Pensio',
    link: 'http://persmin.nic.in/',
  },
  {
    name: 'Judgments',
    link: 'http://judis.nic.in/',
  },
  {
    name: 'Income Tax Department - India',
    link: 'http://www.incometaxindiapr.gov.in',
  },
  {
    name: 'Government of India',
    link: 'http://india.gov.in/',
  },
  {
    name: 'Ministry of Finance',
    link: 'http://finmin.nic.in/',
  },
  {
    name: 'Income Tax of India',
    link: 'http://www.incometaxindia.gov.in/',
  },
  {
    name: 'Central Board of Excise and Customs',
    link: 'http://www.cbec.gov.in/',
  },
]

const HelpfulLinks = () => {
  const classes = useStyles()
  return (
    <div>
      <Layout>
        <SEO title="Important Links" />
        <NewHeader title="Links" image={Links} />
        <Container className={classes.marginTop84}>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item md={8} xs={12}>
              <Paper square style={{ marginTop: 24 }}>
                <div style={{ backgroundColor: '#F00', padding: 4 }}></div>
                {data.map((v, k) => (
                  <ExpansionPanel key={k}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} color="primary">
                        <b>
                          {k + 1}. &nbsp;&nbsp; {v.name}
                        </b>
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography>
                        <Link href="#">{v.link}</Link>
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </div>
  )
}

export default HelpfulLinks
